<template>
	<div :class="`rootVM ${show ? '' : 'hide'}`">
		<div class="content">
			<div class="header">
				모니터링 CCTV 그룹 설정
				<i class="fa fa-times" @click="onClose"></i>
			</div>
			<div class="body">
				<div class="body-top">
					<select v-model="selectedCctvGrpId">
						<option value="">신규그룹추가</option>
						<option v-for="cctvGrp in cctvGrpList" :key="cctvGrp.cctvGrpId" :value="cctvGrp.cctvGrpId">{{ cctvGrp.cctvGrpNm }}</option>
					</select>
					<button @click="clickSaveCctvGrp">저장</button>
					<button v-if="isEditMode" @click="clickDelCctvGrp">삭제</button>
				</div>
				<div class="body-bottom">
					<ul>
						<li>
							<span>CCTV그룹명</span>
							<input type="text" class="cctv-group-name" v-model="detailsItem.cctvGrpNm" />
						</li>
						<li class="cctv-tree">
							<span>대상 CCTV</span>
							<div class="cctv-tree-content">
								<ul>
									<li>
										<label>
											<i
												:class="`fa-regular ${
													cctvTreeList.filter(siteSector => siteSector.checked === true).length === cctvTreeList.length
														? 'fa-square-check'
														: 'fa-square'
												}`"
												@click="clickAll"
											></i>
											<span @click="clickSpreadFold($event)">전체 현장</span>
										</label>
										<ul ref="siteSectorRef">
											<li v-for="(siteSector, i) in cctvTreeList" :key="siteSector.cd">
												<label>
													<i :class="`fa-regular ${siteSector.checked ? 'fa-square-check' : 'fa-square'}`" @click="clickSiteSector(i)"></i>
													<span @click="clickSpreadFold($event)">{{ siteSector.cdNm }} 현장</span>
												</label>
												<ul ref="siteRef">
													<li v-for="(site, j) in siteSector.siteList" :key="site.siteId">
														<label>
															<i :class="`fa-regular ${site.checked ? 'fa-square-check' : 'fa-square'}`" @click="clickSite(i, j)"></i>
															<span @click="clickSpreadFold($event)">{{ site.siteNm }}</span>
														</label>
														<ul ref="cctvRef">
															<li v-for="(cctv, k) in site.cctvList" :key="`${cctv.siteId}${cctv.cctvId}`">
																<label>
																	<i :class="`fa-regular ${cctv.checked ? 'fa-square-check' : 'fa-square'}`" @click="clickCctv(i, j, k)"></i>
																	<span>{{ cctv.cctvInstallPlaceCont }}</span>
																</label>
															</li>
														</ul>
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</li>
						<li class="role-tree">
							<span>
								대상 모니터링 CCTV 그룹 권한
								<button @click="clickAddCctvRoleGrp($event)">추가</button>
							</span>

							<div class="role-tree-content">
								<div>
									<div></div>
									<div>권한코드</div>
									<div>권한코드명</div>
									<div>CCTV 그룹명</div>
									<div></div>
								</div>
								<div v-for="(cctvGrpRole, i) in cctvGrpRoleList" :key="i">
									<div>
										<i
											v-if="
												(!isEditMode && cctvGrpRole.cctvGrpId) ||
												(isEditMode && cctvGrpRole.cctvGrpId && detailsItem.cctvGrpId != cctvGrpRole.cctvGrpId)
											"
											class="fa-solid fa-square-xmark"
											style="color: orange; cursor: not-allowed"
										></i>
										<i
											v-if="
												(!isEditMode && !cctvGrpRole.cctvGrpId) ||
												(isEditMode && (!cctvGrpRole.cctvGrpId || detailsItem.cctvGrpId == cctvGrpRole.cctvGrpId))
											"
											:class="`fa-regular ${cctvGrpRole.checked ? 'fa-square-check' : 'fa-square'}`"
											@click="clickCctvGrpRoleRow(i)"
										></i>
									</div>
									<div>{{ cctvGrpRole.cctvGrpRoleCd }}</div>
									<div>{{ cctvGrpRole.cctvGrpRoleNm }}</div>
									<div>{{ cctvGrpRole.cctvGrpNm }}</div>
									<div><i class="fa fa-pencil" @click="clickEditCctvRoleGrp(i)"></i></div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div :class="`cctv-role-grp-popup ${isShowRoleGrpPopup ? 'show' : ''}`">
			<div class="content">
				<div class="header">
					{{ isCctvRoleGrpEditMode ? '그룹 권한 수정' : '신규 그룹 권한 추가' }}
					<i class="fa fa-times" @click="closeCctvGrpRolePopup"></i>
				</div>
				<div class="body">
					<div>
						<label>권한코드</label>
						<input type="text" v-model="detailsItemRoleGrp.cctvGrpRoleCd" :disabled="isCctvRoleGrpEditMode" />
					</div>
					<div>
						<label>권한코드명</label>
						<input type="text" v-model="detailsItemRoleGrp.cctvGrpRoleNm" />
					</div>
				</div>
				<div class="footer">
					<button @click="clickSaveCctvRoleGrp">저장</button>
					<button v-if="isCctvRoleGrpEditMode" @click="clickDelCctvGrpRole">삭제</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiIndex from '../api/index';

const cctvGrpUrl = apiIndex.cctvGrp;
let axiosExtention;

export default {
	props: {
		show: { type: Boolean, required: true },
		onClose: { type: Function, required: true },
	},
	data: () => ({
		// CCTV 그룹 데이터
		cctvGrpList: [],
		selectedCctvGrpId: '',
		detailsItem: {
			cctvGrpId: '',
			cctvGrpNm: '',
			cctvIdList: [],
			cctvGrpRoleCdList: [],
		},
		isEditMode: false,
		// CCTV 트리 데이터
		cctvTreeList: [],
		// CCTV그룹권한 데이터
		cctvGrpRoleList: [],
		detailsItemRoleGrp: {
			cctvGrpRoleCd: '',
			cctvGrpRoleNm: '',
		},
		isShowRoleGrpPopup: false,
		isCctvRoleGrpEditMode: false,
	}),
	async created() {
		axiosExtention = this.$jarvisExtention.axiosExtention;
	},
	watch: {
		async show() {
			if (this.show) {
				this.getBaseData();
				this.getCctvGrp();
			} else {
				this.initData();
				this.cctvTreeList = [];
				this.cctvGrpRoleList = [];
			}
		},
		selectedCctvGrpId(newVal) {
			// 신규그룹추가의 경우
			if (!newVal) {
				this.isEditMode = false;
				this.initData();
				this.getBaseData();
			} else {
				const target = this.cctvGrpList.find(cctvGrp => cctvGrp.cctvGrpId === newVal);
				this.detailsItem = { ...target };

				// 대상 CCTV 체크 처리
				this.cctvTreeList.forEach(siteSector => {
					siteSector.checked = false;
					siteSector.siteList.forEach(site => {
						site.checked = false;
						site.cctvList.forEach(cctv => {
							cctv.checked = false;
							if (this.detailsItem.cctvIdList.includes(cctv.cctvId)) {
								cctv.checked = true;
							}
						});
					});
				});

				// 대상 CCTV 권한 그룹 체크 처리
				this.cctvGrpRoleList.forEach(cctvGrpRole => {
					cctvGrpRole.checked = false;
					if (this.detailsItem.cctvGrpRoleCdList.includes(cctvGrpRole.cctvGrpRoleCd)) {
						cctvGrpRole.checked = true;
					}
				});

				this.isEditMode = true;
			}

			this.checkStatus();
		},
	},
	methods: {
		// 초기화
		initData() {
			this.detailsItem = {
				cctvGrpId: '',
				cctvGrpNm: '',
				cctvIdList: [],
				cctvGrpRoleCdList: [],
			};
			this.selectedCctvGrpId = '';
			this.isEditMode = false;
			this.detailsItemRoleGrp = {
				cctvGrpRoleCd: '',
				cctvGrpRoleNm: '',
			};
			this.isShowRoleGrpPopup = false;
			this.isCctvRoleGrpEditMode = false;
		},
		// CCTV 그룹 설정 기본 데이터 패칭
		getBaseData() {
			this.$axios
				.post(cctvGrpUrl.viewCctvGrp)
				.then(({ data }) => {
					const siteSectorDvsnCdOptions = data.siteSectorDvsnCdOptions; // 모든 부문구분코드 리스트
					const siteOptions = data.siteOptions; // 모든 현장 리스트
					const cctvOptions = data.cctvOptions; // 모든 CCTV 리스트
					const siteResult = siteOptions; // finalResult에 저장될 현장 레벨 결과물
					const finalResult = siteSectorDvsnCdOptions; // cctvTreeList에 저장될 최종 결과물

					// 현장 데이터에 cctv 데이터 삽입
					cctvOptions.forEach(cctv => {
						const idx = siteResult.findIndex(site => site.siteId === cctv.siteId);
						// cctv 데이터에 입력된 현장이 있으면..
						if (idx > -1) {
							// 현장 데이터에 cctv 리스트 데이터가 초기화되어 있지 않다면..
							if (!siteResult[idx].cctvList) {
								siteResult[idx].cctvList = [];
							}
							// CCTV 데이터를 현장 데이터에 삽입
							cctv.checked = false;
							siteResult[idx].cctvList.push(cctv);
						}
					});
					// 부문구분 데이터에 현장 데이터 삽입
					siteResult.forEach(site => {
						const idx = siteSectorDvsnCdOptions.findIndex(siteSector => siteSector.cd === site.siteSectorDvsnCd);
						// 현장에 등록된 부문구분이 실제 존재하면..
						if (idx > -1) {
							// 부문구분 리스트에 현장 데이터가 초기화되어 있지 않다면..
							if (!finalResult[idx].siteList) {
								finalResult[idx].siteList = [];
							}
							// 부문구분 리스트에 현장 데이터를 삽입
							site.checked = false;
							finalResult[idx].siteList.push(site);
						}
					});

					// 부문구분 하위에 CCTV가 없으면 배열에서 제거함
					let noCctvSiteSectorIdx = [];
					finalResult.forEach((siteSector, i) => {
						siteSector.checked = false;

						// 부분적으로 현장에 속한 CCTV가 없는 경우 특정 현장만 제거
						if (siteSector.siteList && siteSector.siteList.length > 0) {
							let noCctvSiteIdx = [];
							siteSector.siteList.forEach((site, j) => {
								if (!site.cctvList || site.cctvList.length < 1) {
									noCctvSiteIdx.push(j);
								}
							});

							// 인덱스가 높은 순으로 제거해야 삭제 후 원본이 변해도 문제가 없음
							noCctvSiteIdx
								.sort((a, b) => b - a)
								.forEach(idx => {
									siteSector.siteList.splice(idx, 1);
								});
						}

						// 이 부문구분에 속한 현장이 없으면 제거할 리스트에 추가
						if (!siteSector.siteList || siteSector.siteList.length < 1) {
							noCctvSiteSectorIdx.push(i);
						}
					});

					// 인덱스가 높은 순으로 제거해야 삭제 후 원본이 변해도 문제가 없음
					noCctvSiteSectorIdx
						.sort((a, b) => b - a)
						.forEach(idx => {
							finalResult.splice(idx, 1);
						});

					this.cctvTreeList = finalResult;
					this.cctvGrpRoleList = data.cctvGrpRoleCdOptions.map(cctvGrpRole => {
						cctvGrpRole.checked = false;
						return cctvGrpRole;
					});
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// CCTV 그룹 권한 목록 조회
		async getCctvGrpRoleList() {
			return await this.$axios
				.post(cctvGrpUrl.inqCctvGrpRole)
				.then(({ data }) => {
					if (data) {
						this.cctvGrpRoleList = data.map(cctvGrpRole => {
							cctvGrpRole.checked = false;
							return cctvGrpRole;
						});
					}
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// 모든 CCTV 그룹 정보 조회
		getCctvGrp() {
			this.$axios
				.post(cctvGrpUrl.inqCctvGrp)
				.then(({ data }) => {
					this.cctvGrpList = data;
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		// CCTV 트리: 체크박스의 체크 상태에 따라 상위 항목 체크해주는 함수
		checkStatus() {
			this.cctvTreeList.forEach((siteSector, i) => {
				let checkedSiteSize = 0;

				siteSector.siteList.forEach((site, j) => {
					let checkedCctvSize = 0;

					site.cctvList.forEach(cctv => {
						if (cctv.checked) {
							checkedCctvSize++;
						}
					});

					if (site.cctvList.length > 0 && site.cctvList.length === checkedCctvSize) {
						this.$set(this.cctvTreeList[i].siteList[j], 'checked', true);
						checkedSiteSize++;
					} else {
						this.$set(this.cctvTreeList[i].siteList[j], 'checked', false);
					}
				});

				if (siteSector.siteList.length > 0 && siteSector.siteList.length === checkedSiteSize) {
					this.$set(this.cctvTreeList[i], 'checked', true);
				} else {
					this.$set(this.cctvTreeList[i], 'checked', false);
				}
			});
		},
		// CCTV 트리: 전체 현장 체크박스 클릭
		clickAll() {
			const size = this.cctvTreeList.length;
			let checkedSize = 0;
			// 부문구분 체크 상태 확인
			this.cctvTreeList.forEach(siteSector => {
				if (siteSector.checked) {
					checkedSize++;
				}
			});

			if (size === checkedSize) {
				// 부문구분 전체가 체크되어 있으면 모든 CCTV를 체크 해제시킴
				this.cctvTreeList.forEach(siteSector => {
					siteSector.siteList.forEach(site => {
						site.cctvList.forEach(cctv => {
							cctv.checked = false;
						});
					});
				});
			} else {
				// 부문구분 중 하나라도 해제되어 있으면 모든 CCTV를 체크시킴
				this.cctvTreeList.forEach(siteSector => {
					siteSector.siteList.forEach(site => {
						site.cctvList.forEach(cctv => {
							cctv.checked = true;
						});
					});
				});
			}

			this.checkStatus();
		},
		// CCTV 트리: 부문구분 체크박스 클릭
		clickSiteSector(siteSectorIdx) {
			const size = this.cctvTreeList[siteSectorIdx].siteList.length;
			let checkedSize = 0;
			// 부문구분 체크 상태 확인
			this.cctvTreeList[siteSectorIdx].siteList.forEach(site => {
				if (site.checked) {
					checkedSize++;
				}
			});

			if (size === checkedSize) {
				// 특정 부문구분 하위 항목을 모두 체크 해제 처리
				this.cctvTreeList[siteSectorIdx].siteList.forEach(site => {
					site.checked = false;
					site.cctvList.forEach(cctv => {
						cctv.checked = false;
					});
				});
			} else {
				// 특정 부문구분 하위 항목을 모두 체크 처리
				this.cctvTreeList[siteSectorIdx].siteList.forEach(site => {
					site.checked = true;
					site.cctvList.forEach(cctv => {
						cctv.checked = true;
					});
				});
			}

			this.checkStatus();
		},
		// CCTV 트리: 현장 체크박스 클릭
		clickSite(siteSectorIdx, siteIdx) {
			const size = this.cctvTreeList[siteSectorIdx].siteList[siteIdx].cctvList.length;
			let checkedSize = 0;
			// 부문구분 체크 상태 확인
			this.cctvTreeList[siteSectorIdx].siteList[siteIdx].cctvList.forEach(site => {
				if (site.checked) {
					checkedSize++;
				}
			});

			if (size === checkedSize) {
				// 특정 부문구분 하위 항목을 모두 체크 처리
				this.cctvTreeList[siteSectorIdx].siteList[siteIdx].cctvList.forEach(cctv => {
					cctv.checked = false;
				});
			} else {
				// 특정 부문구분 하위 항목을 모두 체크 처리
				this.cctvTreeList[siteSectorIdx].siteList[siteIdx].cctvList.forEach(cctv => {
					cctv.checked = true;
				});
			}

			this.checkStatus();
		},
		// CCTV 트리: CCTV 체크박스 클릭
		clickCctv(siteSectorIdx, siteIdx, cctvIdx) {
			const isChecked = this.cctvTreeList[siteSectorIdx].siteList[siteIdx].cctvList[cctvIdx].checked;
			this.cctvTreeList[siteSectorIdx].siteList[siteIdx].cctvList[cctvIdx].checked = !isChecked;
			this.checkStatus();
		},
		// CCTV 트리: 이름 클릭 => 접었다 펼치기
		clickSpreadFold(e) {
			e.stopPropagation();
			e.target.closest('li').querySelector('ul').classList.toggle('show');
		},
		// CCTV그룹권한 추가 버튼 클릭
		clickAddCctvRoleGrp(e) {
			e.stopPropagation();
			this.isShowRoleGrpPopup = true;
		},
		// CCTV그룹권한 수정 버튼 클릭
		clickEditCctvRoleGrp(idx) {
			this.detailsItemRoleGrp = { ...this.cctvGrpRoleList[idx] };
			this.isShowRoleGrpPopup = true;
			this.isCctvRoleGrpEditMode = true;
		},
		// CCTV그룹권한 삭제 버튼 클릭
		clickDelCctvGrpRole() {
			if (confirm('삭제하시겠습니까?')) {
				this.$axios
					.post(cctvGrpUrl.delCctvGrpRole, this.detailsItemRoleGrp)
					.then(async () => {
						this.isShowRoleGrpPopup = false;
						this.detailsItemRoleGrp = {};
						this.isCctvRoleGrpEditMode = false;
						await this.getCctvGrpRoleList();

						if (this.isEditMode) {
							this.cctvGrpRoleList.forEach(cctvGrpRole => {
								cctvGrpRole.checked = false;
								if (this.detailsItem.cctvGrpRoleCdList.includes(cctvGrpRole.cctvGrpRoleCd)) {
									cctvGrpRole.checked = true;
								}
							});
						}
					})
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		// CCTV그룹권한 팝업: 닫기 버튼 클릭
		closeCctvGrpRolePopup() {
			this.isShowRoleGrpPopup = false;
			this.isCctvRoleGrpEditMode = false;
			this.detailsItemRoleGrp = {};
		},
		// CCTV그룹권한 팝업: 저장 버튼 클릭
		async clickSaveCctvRoleGrp() {
			const cctvGrpRoleCd = this.detailsItemRoleGrp.cctvGrpRoleCd;
			const cctvGrpRoleNm = this.detailsItemRoleGrp.cctvGrpRoleNm;

			if (!cctvGrpRoleCd || !cctvGrpRoleNm) {
				return alert('입력란을 다시 확인해주십시오');
			}

			let isSuccess = false;

			if (this.isCctvRoleGrpEditMode) {
				// 수정
				await this.$axios
					.post(cctvGrpUrl.updCctvGrpRole, this.detailsItemRoleGrp)
					.then(() => {
						const idx = this.cctvGrpRoleList.findIndex(cctvGrpRole => cctvGrpRole.cctvGrpRoleCd === cctvGrpRoleCd);
						if (idx > -1) {
							this.cctvGrpRoleList[idx].cctvGrpRoleNm = cctvGrpRoleNm;
							isSuccess = true;
						}
					})
					.catch(axiosExtention.buildErrorHandler());
			} else {
				// 신규 추가
				await this.$axios
					.post(cctvGrpUrl.insCctvGrpRole, this.detailsItemRoleGrp)
					.then(() => {
						isSuccess = true;
					})
					.catch(axiosExtention.buildErrorHandler());
			}

			if (isSuccess) {
				this.isShowRoleGrpPopup = false;
				this.detailsItemRoleGrp = {};
				this.isCctvRoleGrpEditMode = false;
				await this.getCctvGrpRoleList();

				if (this.isEditMode) {
					this.cctvGrpRoleList.forEach(cctvGrpRole => {
						cctvGrpRole.checked = false;
						if (this.detailsItem.cctvGrpRoleCdList.includes(cctvGrpRole.cctvGrpRoleCd)) {
							cctvGrpRole.checked = true;
						}
					});
				}
			}
		},
		// CCTV그룹권한 팝업: 삭제 버튼 클릭
		clickDelCctvRoleGrp() {
			if (confirm('삭제하시겠습니까?')) {
				this.$axios
					.post(cctvGrpUrl.delCctvGrpRole, this.detailsItemRoleGrp)
					.then(async () => {
						this.isShowRoleGrpPopup = false;
						this.detailsItemRoleGrp = {};
						this.isCctvRoleGrpEditMode = false;
						await this.getCctvGrpRoleList();

						if (this.isEditMode) {
							this.cctvGrpRoleList.forEach(cctvGrpRole => {
								cctvGrpRole.checked = false;
								if (this.detailsItem.cctvGrpRoleCdList.includes(cctvGrpRole.cctvGrpRoleCd)) {
									cctvGrpRole.checked = true;
								}
							});
						}
					})
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		// CCTV그룹권한에서 체크박스 클릭 시
		clickCctvGrpRoleRow(cctvGrpRoleIdx) {
			const cctvGrpRole = this.cctvGrpRoleList[cctvGrpRoleIdx];
			this.$set(this.cctvGrpRoleList[cctvGrpRoleIdx], 'checked', !cctvGrpRole.checked);
		},
		// CCTV 그룹 데이터를 추가/수정 시 유효성 검사
		validateForm() {
			if (!this.detailsItem.cctvGrpNm) {
				alert('그룹명을 입력해주십시오');
				return false;
			}

			// if (!this.detailsItem.cctvIdList.length) {
			// 	alert('CCTV를 체크해주십시오');
			// 	return false;
			// }

			// if (!this.detailsItem.cctvGrpRoleCdList.length) {
			// 	alert('CCTV그룹권한을 체크해주십시오');
			// 	return false;
			// }

			return true;
		},
		// CCTV그룹 저장 버튼 클릭
		clickSaveCctvGrp() {
			// cctv 데이터 중 check 되어 있는 데이터 찾아서 배열로 만듦
			this.detailsItem.cctvIdList = [];
			this.cctvTreeList.forEach(siteSector => {
				siteSector.siteList.forEach(site => {
					site.cctvList.forEach(cctv => {
						// 현재 cctv에 체크되어 있는 것들만 데이터에 추가
						if (cctv.checked) {
							this.detailsItem.cctvIdList.push(cctv.cctvId);
						}
					});
				});
			});

			// cctv 그룹 권한 데이터 중 check 되어 있는 데이터 찾아서 배열로 만듦
			this.detailsItem.cctvGrpRoleCdList = [];
			this.cctvGrpRoleList.forEach(cctvGrpRole => {
				if (cctvGrpRole.checked) {
					this.detailsItem.cctvGrpRoleCdList.push(cctvGrpRole.cctvGrpRoleCd);
				}
			});

			// 전송 데이터 유효성 검사
			if (this.validateForm() && confirm('저장하시겠습니까?')) {
				if (this.isEditMode) {
					// 수정
					this.$axios
						.post(cctvGrpUrl.updCctvGrp, this.detailsItem)
						.then(async () => {
							// 수정 후 기존 CCTV 그룹 데이터 업데이트
							const idx = this.cctvGrpList.findIndex(cctvGrp => cctvGrp.cctvGrpId === this.detailsItem.cctvGrpId);
							if (idx > -1) {
								this.cctvGrpList[idx] = { ...this.detailsItem };
							}
							await this.getCctvGrpRoleList();
							// 수정 후 대상 CCTV 권한 그룹 체크 처리
							this.cctvGrpRoleList.forEach(cctvGrpRole => {
								cctvGrpRole.checked = false;
								if (this.detailsItem.cctvGrpRoleCdList.includes(cctvGrpRole.cctvGrpRoleCd)) {
									cctvGrpRole.checked = true;
								}
							});
						})
						.catch(axiosExtention.buildErrorHandler());
				} else {
					// 신규 추가
					this.$axios
						.post(cctvGrpUrl.insCctvGrp, this.detailsItem)
						.then(() => {
							this.initData();
							this.getBaseData();
							this.getCctvGrp();
						})
						.catch(axiosExtention.buildErrorHandler());
				}
			}
		},
		// CCTV 그룹 삭제 버튼 클릭
		clickDelCctvGrp() {
			if (confirm('삭제하시겠습니까?')) {
				this.$axios
					.post(cctvGrpUrl.delCctvGrp, this.detailsItem)
					.then(() => {
						this.initData();
						this.getBaseData();
						this.getCctvGrp();
					})
					.catch(axiosExtention.buildErrorHandler());
			}
		},
	},
};
</script>

<style scoped>
.rootVM {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.4);
	z-index: 200;
}

.rootVM input {
	padding: 2px 4px;
}

.rootVM input:disabled {
	background: #ddd;
	cursor: not-allowed !important;
}

.rootVM .content {
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 640px;
	height: 640px;
	background: white;
	border-radius: 4px;
	color: black;
	font-size: 1.4em;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.rootVM .content button {
	border: 1px solid black;
	background: #ddd;
	font-weight: bold;
	padding: 6px 10px;
}

.rootVM .content .header {
	display: flex;
	justify-content: center;
	font-size: 1.2em;
	font-weight: bold;
}

.rootVM .content .header .fa-times {
	margin-left: auto;
	cursor: pointer;
}

.rootVM .content .body .body-top {
	display: flex;
	gap: 6px;
	margin: 30px 0;
}

.rootVM .content .body .body-top select {
	flex: 1;
	border: 1px solid black;
	font-size: 1em;
	padding: 0 10px;
}

.rootVM .content .body .body-bottom > ul {
	display: flex;
	flex-direction: column;
	gap: 10px;
	list-style: inside;
}

.rootVM .content .body .body-bottom > ul li > span {
	font-weight: bold;
	margin-right: 10px;
}

.rootVM .content .body .body-bottom > ul li > span button {
	margin-bottom: 10px;
}

.rootVM .content .body .body-bottom > ul .cctv-group-name {
	border-bottom: 1px solid black;
	width: 240px;
	font-size: 1em;
	cursor: text;
}

.rootVM .content .body .body-bottom .cctv-tree .cctv-tree-content {
	width: 100%;
	height: 180px;
	max-height: 180px;
	overflow-y: auto;
	border: 1px solid black;
	padding-bottom: 30px;
	margin-top: 12px;
}

.rootVM .content .body .body-bottom .cctv-tree .cctv-tree-content ul {
	list-style: none;
	padding: 0;
	margin-left: 24px;
}

.rootVM .content .body .body-bottom .cctv-tree .cctv-tree-content > ul > li > ul > li ul {
	display: none;
}

.rootVM .content .body .body-bottom .cctv-tree .cctv-tree-content > ul > li > ul > li ul.show {
	display: block;
}

.rootVM .content .body .body-bottom .cctv-tree .cctv-tree-content ul li label {
	width: fit-content;
	font-weight: normal;
	margin: 0;
	margin-top: 6px;
	cursor: pointer;
}

.rootVM .content .body .body-bottom .cctv-tree .cctv-tree-content ul li label .fa-regular {
	margin-right: 6px;
}

.rootVM .content .body .body-bottom .role-tree .role-tree-content {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 180px;
	max-height: 180px;
	overflow-y: auto;
	border: 1px solid black;
	overflow-y: auto;
}

.rootVM .content .body .body-bottom .role-tree .role-tree-content > div {
	display: flex;
	border-bottom: 1px solid #ddd;
}

.rootVM .content .body .body-bottom .role-tree .role-tree-content > div:hover {
}

.rootVM .content .body .body-bottom .role-tree .role-tree-content > div > div {
	flex: 1;
	display: flex;
	align-content: center;
	justify-content: center;
	padding: 6px;
}

.rootVM .content .body .body-bottom .role-tree .role-tree-content > div > div:nth-child(1) {
	flex: 1 1 40px;
	max-width: 40px;
}
.rootVM .content .body .body-bottom .role-tree .role-tree-content > div > div:nth-child(1) .fa-regular {
	cursor: pointer;
}
.rootVM .content .body .body-bottom .role-tree .role-tree-content > div > div:nth-child(2) {
}
.rootVM .content .body .body-bottom .role-tree .role-tree-content > div > div:nth-child(3) {
}
.rootVM .content .body .body-bottom .role-tree .role-tree-content > div > div:nth-child(4) {
}
.rootVM .content .body .body-bottom .role-tree .role-tree-content > div > div:nth-child(5) {
	flex: 1 1 40px;
	max-width: 40px;
}

.rootVM .content .body .body-bottom .role-tree .role-tree-content > div:first-child {
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	color: black;
	font-weight: bold;
	background: #ddd;
	border-bottom: 1px solid black;
}

.rootVM .cctv-role-grp-popup {
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	align-content: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.4);
}

.rootVM .cctv-role-grp-popup.show {
	display: flex;
}

.rootVM .cctv-role-grp-popup .content {
	display: flex;
	flex-direction: column;
	width: 400px;
	height: 240px;
	margin: auto;
}

.rootVM .cctv-role-grp-popup .content > div {
	flex: 1;
}

.rootVM .cctv-role-grp-popup .content .header {
	flex: 1 1 22px;
	max-height: 22px;
}

.rootVM .cctv-role-grp-popup .content .body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 12px;
}

.rootVM .cctv-role-grp-popup .content .body label {
	width: 90px;
}

.rootVM .cctv-role-grp-popup .content .body input {
	border-bottom: 1px solid black;
	margin-left: 12px;
	cursor: text;
}

.rootVM .cctv-role-grp-popup .content .footer {
	display: flex;
	flex: 1 1 30px;
	max-height: 30px;
}

.rootVM .cctv-role-grp-popup .content .footer button:first-child {
	margin-left: auto;
	margin-right: 12px;
}
</style>
