var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "rootVM " + (_vm.show ? "" : "hide") }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "header" }, [
        _vm._v(" 모니터링 CCTV 그룹 설정 "),
        _c("i", { staticClass: "fa fa-times", on: { click: _vm.onClose } })
      ]),
      _c("div", { staticClass: "body" }, [
        _c("div", { staticClass: "body-top" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedCctvGrpId,
                  expression: "selectedCctvGrpId"
                }
              ],
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedCctvGrpId = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c("option", { attrs: { value: "" } }, [_vm._v("신규그룹추가")]),
              _vm._l(_vm.cctvGrpList, function(cctvGrp) {
                return _c(
                  "option",
                  {
                    key: cctvGrp.cctvGrpId,
                    domProps: { value: cctvGrp.cctvGrpId }
                  },
                  [_vm._v(_vm._s(cctvGrp.cctvGrpNm))]
                )
              })
            ],
            2
          ),
          _c("button", { on: { click: _vm.clickSaveCctvGrp } }, [
            _vm._v("저장")
          ]),
          _vm.isEditMode
            ? _c("button", { on: { click: _vm.clickDelCctvGrp } }, [
                _vm._v("삭제")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "body-bottom" }, [
          _c("ul", [
            _c("li", [
              _c("span", [_vm._v("CCTV그룹명")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.detailsItem.cctvGrpNm,
                    expression: "detailsItem.cctvGrpNm"
                  }
                ],
                staticClass: "cctv-group-name",
                attrs: { type: "text" },
                domProps: { value: _vm.detailsItem.cctvGrpNm },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.detailsItem, "cctvGrpNm", $event.target.value)
                  }
                }
              })
            ]),
            _c("li", { staticClass: "cctv-tree" }, [
              _c("span", [_vm._v("대상 CCTV")]),
              _c("div", { staticClass: "cctv-tree-content" }, [
                _c("ul", [
                  _c("li", [
                    _c("label", [
                      _c("i", {
                        class:
                          "fa-regular " +
                          (_vm.cctvTreeList.filter(function(siteSector) {
                            return siteSector.checked === true
                          }).length === _vm.cctvTreeList.length
                            ? "fa-square-check"
                            : "fa-square"),
                        on: { click: _vm.clickAll }
                      }),
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.clickSpreadFold($event)
                            }
                          }
                        },
                        [_vm._v("전체 현장")]
                      )
                    ]),
                    _c(
                      "ul",
                      { ref: "siteSectorRef" },
                      _vm._l(_vm.cctvTreeList, function(siteSector, i) {
                        return _c("li", { key: siteSector.cd }, [
                          _c("label", [
                            _c("i", {
                              class:
                                "fa-regular " +
                                (siteSector.checked
                                  ? "fa-square-check"
                                  : "fa-square"),
                              on: {
                                click: function($event) {
                                  return _vm.clickSiteSector(i)
                                }
                              }
                            }),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.clickSpreadFold($event)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(siteSector.cdNm) + " 현장")]
                            )
                          ]),
                          _c(
                            "ul",
                            { ref: "siteRef", refInFor: true },
                            _vm._l(siteSector.siteList, function(site, j) {
                              return _c("li", { key: site.siteId }, [
                                _c("label", [
                                  _c("i", {
                                    class:
                                      "fa-regular " +
                                      (site.checked
                                        ? "fa-square-check"
                                        : "fa-square"),
                                    on: {
                                      click: function($event) {
                                        return _vm.clickSite(i, j)
                                      }
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.clickSpreadFold($event)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(site.siteNm))]
                                  )
                                ]),
                                _c(
                                  "ul",
                                  { ref: "cctvRef", refInFor: true },
                                  _vm._l(site.cctvList, function(cctv, k) {
                                    return _c(
                                      "li",
                                      { key: "" + cctv.siteId + cctv.cctvId },
                                      [
                                        _c("label", [
                                          _c("i", {
                                            class:
                                              "fa-regular " +
                                              (cctv.checked
                                                ? "fa-square-check"
                                                : "fa-square"),
                                            on: {
                                              click: function($event) {
                                                return _vm.clickCctv(i, j, k)
                                              }
                                            }
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(cctv.cctvInstallPlaceCont)
                                            )
                                          ])
                                        ])
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ])
              ])
            ]),
            _c("li", { staticClass: "role-tree" }, [
              _c("span", [
                _vm._v(" 대상 모니터링 CCTV 그룹 권한 "),
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.clickAddCctvRoleGrp($event)
                      }
                    }
                  },
                  [_vm._v("추가")]
                )
              ]),
              _c(
                "div",
                { staticClass: "role-tree-content" },
                [
                  _vm._m(0),
                  _vm._l(_vm.cctvGrpRoleList, function(cctvGrpRole, i) {
                    return _c("div", { key: i }, [
                      _c("div", [
                        (!_vm.isEditMode && cctvGrpRole.cctvGrpId) ||
                        (_vm.isEditMode &&
                          cctvGrpRole.cctvGrpId &&
                          _vm.detailsItem.cctvGrpId != cctvGrpRole.cctvGrpId)
                          ? _c("i", {
                              staticClass: "fa-solid fa-square-xmark",
                              staticStyle: {
                                color: "orange",
                                cursor: "not-allowed"
                              }
                            })
                          : _vm._e(),
                        (!_vm.isEditMode && !cctvGrpRole.cctvGrpId) ||
                        (_vm.isEditMode &&
                          (!cctvGrpRole.cctvGrpId ||
                            _vm.detailsItem.cctvGrpId == cctvGrpRole.cctvGrpId))
                          ? _c("i", {
                              class:
                                "fa-regular " +
                                (cctvGrpRole.checked
                                  ? "fa-square-check"
                                  : "fa-square"),
                              on: {
                                click: function($event) {
                                  return _vm.clickCctvGrpRoleRow(i)
                                }
                              }
                            })
                          : _vm._e()
                      ]),
                      _c("div", [_vm._v(_vm._s(cctvGrpRole.cctvGrpRoleCd))]),
                      _c("div", [_vm._v(_vm._s(cctvGrpRole.cctvGrpRoleNm))]),
                      _c("div", [_vm._v(_vm._s(cctvGrpRole.cctvGrpNm))]),
                      _c("div", [
                        _c("i", {
                          staticClass: "fa fa-pencil",
                          on: {
                            click: function($event) {
                              return _vm.clickEditCctvRoleGrp(i)
                            }
                          }
                        })
                      ])
                    ])
                  })
                ],
                2
              )
            ])
          ])
        ])
      ])
    ]),
    _c(
      "div",
      {
        class: "cctv-role-grp-popup " + (_vm.isShowRoleGrpPopup ? "show" : "")
      },
      [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "header" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isCctvRoleGrpEditMode
                    ? "그룹 권한 수정"
                    : "신규 그룹 권한 추가"
                ) +
                " "
            ),
            _c("i", {
              staticClass: "fa fa-times",
              on: { click: _vm.closeCctvGrpRolePopup }
            })
          ]),
          _c("div", { staticClass: "body" }, [
            _c("div", [
              _c("label", [_vm._v("권한코드")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.detailsItemRoleGrp.cctvGrpRoleCd,
                    expression: "detailsItemRoleGrp.cctvGrpRoleCd"
                  }
                ],
                attrs: { type: "text", disabled: _vm.isCctvRoleGrpEditMode },
                domProps: { value: _vm.detailsItemRoleGrp.cctvGrpRoleCd },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.detailsItemRoleGrp,
                      "cctvGrpRoleCd",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("div", [
              _c("label", [_vm._v("권한코드명")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.detailsItemRoleGrp.cctvGrpRoleNm,
                    expression: "detailsItemRoleGrp.cctvGrpRoleNm"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.detailsItemRoleGrp.cctvGrpRoleNm },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.detailsItemRoleGrp,
                      "cctvGrpRoleNm",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "footer" }, [
            _c("button", { on: { click: _vm.clickSaveCctvRoleGrp } }, [
              _vm._v("저장")
            ]),
            _vm.isCctvRoleGrpEditMode
              ? _c("button", { on: { click: _vm.clickDelCctvGrpRole } }, [
                  _vm._v("삭제")
                ])
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div"),
      _c("div", [_vm._v("권한코드")]),
      _c("div", [_vm._v("권한코드명")]),
      _c("div", [_vm._v("CCTV 그룹명")]),
      _c("div")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }